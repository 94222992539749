import React, { FC, useState } from "react"
import Parallax from 'react-rellax'

export const Hero: FC<{block: any}> = ({block}) => {
  return (
    <div className="hero-outer">
      {block.image &&
      <div className="hero" style={{ backgroundImage: `url(${block.image.fixed.src})` }}>
        <div className="container">
          <Parallax speed={-4} >
          <div className="hero-headline">{block.headline}</div>
          </Parallax>
          <Parallax speed={3} >
          <div className="hero-label">{block.label}</div>
          </Parallax>
        </div>
      </div>
    }
    </div>
  )
}
