import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react"

export const Booking: FC = () => {
  const intl = useIntl();

  return (
    <div>
        <iframe src={"https://w24.roomsoftware.com/" + intl.locale + "/oldschool"} width="100%" name="iFrame" title="Direktbuchung"></iframe>
    </div>
  )
}
