import React, { FC, useState } from "react"
import Slider from 'react-slick'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Parallax from 'react-rellax'
import AutumVideo1 from '../../videos/autum/video1.mp4'
import WinterVideo1 from '../../videos/winter/video1.mp4'
import SpringVideo1 from '../../videos/spring/video1.mp4'
import SummerVideo1 from '../../videos/summer/video1.mp4'
import AutumVideo2 from '../../videos/autum/video2.mp4'
import WinterVideo2 from '../../videos/winter/video2.mp4'
import SpringVideo2 from '../../videos/spring/video1.mp4'
import SummerVideo2 from '../../videos/summer/video2.mp4'

const settingsLeft = {
  arrows: false,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  cssEase: "linear",
  initialSlide: 0
}

const settingsRight = {
  arrows: false,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
  cssEase: "linear",
}

const getVideo1 = () => {
  const month = new Date().getMonth() + 1
    console.log("month", month)

  if(month <= 2){
    return WinterVideo1
  }

  if(month <= 5){
    return SpringVideo1
  }

  if(month >= 9 &&  month <= 11){
    return AutumVideo1
  }

  if(month >= 11){
    return WinterVideo1
  }

  return SummerVideo1
}

const getVideo2 = () => {
  const month = new Date().getMonth() + 1

    console.log("month", month)
  
  if(month <= 2){
    return WinterVideo2
  }

  if(month <= 5){
    return SpringVideo2
  }

  if(month >= 9 &&  month <= 11){
    return AutumVideo2
  }

  if(month >= 11){
    return WinterVideo2
  }

  return SummerVideo2
}

export const HeroSlider: FC<{data: any}> = ({block}) => {
  return (
    <div className="slider">
      <Slider className="slider-left" {...settingsLeft}>
      {block.images.map(image => (
          <img className="img-fluid" src={image.fixed.src} />
      ))}
      </Slider>
        <Slider className="slider-right" {...settingsRight}>
            <Parallax speed={-4} >
            <video muted autoPlay playsInline loop className="img-fluid" src={getVideo1()} />
            </Parallax>
            <Parallax speed={-4} >
            <video muted autoPlay playsInline loop className="img-fluid" src={getVideo2()} />
            </Parallax>
        </Slider>

        <div className="slider-content">        
        <Parallax speed={2}>
          <h1 className="slider-content-headline">{block.name}</h1>
          <div className="slider-content-text" dangerouslySetInnerHTML={{ __html:documentToHtmlString(JSON.parse(block.description.raw))}}></div>
      </Parallax>
      </div>
    </div>
  )
}
