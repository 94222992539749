import React, { FC, useState } from "react"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Parallax from 'react-rellax'
import ScrollAnimation from 'react-animate-on-scroll';

export const RegionOverview: FC<{ block: any }> = ({ block }) => {

    const intl = useIntl();

    return (
        <div className="region-overview" style={{ backgroundImage: `url(${block.background.fixed.src})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-none d-lg-flex">
                        <div className="intro-text-slogan-placeholder"></div>
                        <div className="intro-text-slogan">
                            <div><Parallax speed={-0.5} centered><span>Wilder Kaiser</span></Parallax></div>
                            <div><Parallax speed={1.4} centered><span>Die Region</span></Parallax></div>
                        </div>
                    </div>
                    <div className="col-md-5 region-overview-content">
                        <ScrollAnimation animateIn="fadeIn">
                            <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.text.raw)) }}></div>
                            <div>{block.teaser}</div>
                            {intl.locale == 'de' &&
                                <Link className="btn-link" to="/ferienregion">{intl.formatMessage({ id: "read_more" })}</Link>
                            }
                            {intl.locale == 'en' &&
                                <Link className="btn-link" to="/holiday-region">{intl.formatMessage({ id: "read_more" })}</Link>
                            }
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 region-overview-image is--left">
                        {block.imageLeft &&
                            <ScrollAnimation animateIn="fadeIn">
                                <img className="img-fluid mt-5 mb-5" src={block.imageLeft.fixed.src} />
                            </ScrollAnimation>
                        }
                    </div>
                    <div className="col-6 region-overview-image is--right">
                        {block.imageRight &&
                            <ScrollAnimation animateIn="fadeIn">
                                <img className="img-fluid mt-5 mb-5" src={block.imageRight.fixed.src} />
                            </ScrollAnimation>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
