import * as React from 'react'
import '@scss/main.scss'
import { Layout } from '@components/layout'
import Components from '@components/components'
import { Footer } from '@components/Footer';
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"
import gtag from '../components/gtag'

const IndexPage = (data) => {
  const intl = useIntl();

  console.log(data)

  if (typeof window !== 'undefined') {
    if (window.location.pathname == '/en/') {
      window.location = '/en/home'
    }

    const locale = data.pageContext.locale.replace("en-US", "en");

    if (intl.locale != locale) {
      window.location = '/' + locale
    }  
  }
  gtag()

  return (
    <Layout data={data}>
      {data.pageContext.data.data.contentfulTextpage.content &&
        data.pageContext.data.data.contentfulTextpage.content.map((block: any) =>
          Components(block)
        )}
      <Helmet>
        <title>{data.pageContext.data.data.contentfulTextpage.name} | Oldschool</title>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
        <script src="https://static.clickskeks.at/1f/33/1f3388bb-a92e-4c3b-9ac1-ffc507870f5d/bundle.js" type="application/javascript"></script>
        <script data-cookieconsent="preferences" async src="https://www.googletagmanager.com/gtag/js?id=G-EGC1QZ1FCF"></script>
      </Helmet>
      <Footer key={data.pageContext.id} data={data.pageContext.navigation} />
    </Layout>
  )
}

export default IndexPage