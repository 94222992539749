import React, { FC, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ScrollAnimation from 'react-animate-on-scroll';

export const ActivityGroup: FC<{block: any}> = ({block}) => {
  return (
    <div className="activity-group" style={{ backgroundImage: `url(${block.background.fixed.src})` }}>
        <div className="container">
        <div className="row">
            <div className="col-md-2 d-none d-lg-block">
                <div className="activity-group-year">
                    <span>{block.name}</span>
                </div>
            </div>
            <div className="col-md-10">
                {block.items && block.items.map((activity: any, index: number) => (
                    <div key={'acitivty' + index + block.name} className="row activity-entry">
                        <div className={index % 2 == 0 ?  'col-md-7 order-lg-1 pl-lg-5' : 'col-md-7 pr-lg-4'}>
                            <ScrollAnimation animateIn="fadeIn">
                                {activity.image &&
                                    <img className="img-fluid mb-4 mb-lg-0" src={activity.image.fixed.src} alt={activity.name} />
                                }
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-5">
                              <ScrollAnimation animateIn="fadeIn">
                            <h2>{activity.name}</h2>
                            <div dangerouslySetInnerHTML={{ __html:documentToHtmlString(JSON.parse(activity.content.raw))}}></div>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    </div>
  )
}
