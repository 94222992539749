import React, { FC, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ScrollAnimation from 'react-animate-on-scroll';

export const Paragraph: FC<{ block: any }> = ({ block }) => {
  return (
    <div className={'paragraph ' + (block.compact ? 'is--compact' : '')}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <ScrollAnimation animateIn="fadeIn">
            {(block.text && block.text.raw) &&
              <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.text.raw)).replace(/\n/g, `</br>`) }}></div>
            }
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  )
}
