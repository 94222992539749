import React from 'react'
import { HeroSlider } from '@components/Slider'
import { IntroText } from './IntroText'
import { CallToAction } from './CallToAction'
import { RegionOverview } from './RegionOverview'
import { SchulhausTeaser } from './SchulhausTeaser'
import { ApartmentOverview } from './ApartmentsOverview'
import { Hero } from './Hero'
import { Paragraph } from './Paragraph'
import { ActivityGroup } from './ActivityGroup'
import { FaqWrapper } from './Faq'
import { ApartmentList } from './ApartmentList'
import { Booking } from './Booking'
import { File } from './File'


const AreaBlocks = {
  ContentfulApartmentOverview: ApartmentOverview,  
    ContentfulSliderFrontpage: HeroSlider,
    ContentfulIntroText: IntroText,
    ContentfulRegionOverview: RegionOverview,
    ContentfulCallToAction: CallToAction,
    ContentfulHero: Hero,
    ContentfulParagraph: Paragraph,
    ContentfulSchulhausTeaser: SchulhausTeaser,
    ContentfulActivityGroup: ActivityGroup,
    ContentfulApartmentList: ApartmentList,
    ContentfulFaqWrapper: FaqWrapper,
    ContentfulBooking: Booking,
    ContentfulFile: File,
}

export default block => {
  if (typeof AreaBlocks[block.__typename] !== 'undefined') {
    return React.createElement(AreaBlocks[block.__typename], {
      key: block.__typename + Math.random(),
      block: block
    })
  }
  return React.createElement(
    () => <div>The component "{block.__typename}" has not been created yet.</div>,
    { key: block.__typename }
  )
}