import React, { FC, useState } from "react"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Link } from "gatsby-plugin-intl";
import Parallax from 'react-rellax'
import ScrollAnimation from 'react-animate-on-scroll';
import { useIntl } from "gatsby-plugin-intl";

export const CallToAction: FC<{block: any}> = ({block}) => {
    const intl = useIntl();

  return (
    <div className="cta">
      <a href={"https://w24.roomsoftware.com/" + intl.locale + "/oldschool"} target="_blank">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
                <ScrollAnimation animateIn="fadeIn">
                  <h2 className="cta-headline">{block.name}</h2>
                <img className="w-100" src={block.image.fixed.src} alt={block.name} />
                  <div className="cta-text" dangerouslySetInnerHTML={{ __html:documentToHtmlString(JSON.parse(block.text.raw))}}></div>
                </ScrollAnimation>
            </div>
          </div>
        </div>
        </a>
    </div>
  )
}
