import React, { FC, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import ScrollAnimation from 'react-animate-on-scroll';

export const SchulhausTeaser: FC<{ block: any }> = ({ block }) => {

    const intl = useIntl();

    return (
        <div className="schulhaus-teaser">
            <div className="schulhaus-teaser-brand">
                <StaticImage src="../../images/schulhaus.svg" alt="Schulhaus" />
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6">            <ScrollAnimation animateIn="fadeIn">

                        <video poster="https://res.cloudinary.com/schulhaus/video/upload/v1599722662/Schulhaus_WildBerry_HP_tcmiex.jpg" className="schulhaus-teaser-image is--left img-fluid mt-5 mb-5" playsInline controls={false} autoPlay loop muted>
                            <source src="https://res.cloudinary.com/schulhaus/video/upload/e_fade:1000,q_auto,vc_h264/v1599722647/Schulhaus_Kaffeetscherl_HP_bjrxa6.mp4" type="video/mp4" />
                            <source src="https://res.cloudinary.com/schulhaus/video/upload/e_fade:1000,q_auto,vc_h264/v1599722647/Schulhaus_Kaffeetscherl_HP_bjrxa6.webm" type="video/webm" />
                        </video>                        </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                            <div className="pr-xl-4 text-white schulhaus-teaser-content mb-4" dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.text.raw)) }}></div>
                        </ScrollAnimation>
                        <a target="_blank" className="btn-link" href="https://www.restaurant-bar-schulhaus.at/">{intl.formatMessage({ id: "read_more" })}</a>
                    </div>
                    <div className="col-md-6">                        <ScrollAnimation animateIn="fadeIn">

                        <video poster="https://res.cloudinary.com/schulhaus/video/upload/v1599722662/Schulhaus_WildBerry_HP_tcmiex.jpg" className="schulhaus-teaser-image is--right img-fluid mt-5 mb-5" playsInline controls={false} autoPlay loop muted>
                            <source src="https://res.cloudinary.com/schulhaus/video/upload/e_fade:1000,q_auto,vc_h264/v1599722700/Schulhaus_Pizza_HP_pjgs23.mp4" type="video/mp4" />
                            <source src="https://res.cloudinary.com/schulhaus/video/upload/e_fade:1000,q_auto,vc_h264/v1599722700/Schulhaus_Pizza_HP_pjgs23.webm" type="video/webm" />
                        </video>                        </ScrollAnimation>

                    </div>
                </div>
            </div>
        </div>
    )
}
