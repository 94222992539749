import React, { FC, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Slider from 'react-slick'
import { Link } from "gatsby-plugin-intl";
import ScrollAnimation from 'react-animate-on-scroll';

const settings = {
  arrows: true,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  rows: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      }
    },
  ]
}


export const ApartmentOverview: FC<{ block: any }> = ({ block }) => {
  return (
    <div className={'apartment-overview ' + (block.compact ? 'is--compact' : '')}>
      {block.background &&
            <ScrollAnimation animateIn="fadeIn">
            <img className="apartment-overview-bg img-fluid" src={block.background.fixed.src} />
          </ScrollAnimation>
      }            
      <div className="container">
        {block.text &&
          <div className="row">
            <div className="col-12">
              <ScrollAnimation animateIn="fadeIn">
                <h2 className="apartment-overview-headline">{block.name}</h2>
              </ScrollAnimation>
            </div>
            <div className="col-md-6">
              <ScrollAnimation animateIn="fadeIn">
                <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.text.raw)) }}></div>
              </ScrollAnimation>
            </div>
          </div>
        }

        <Slider className="apartment-overview-slider" {...settings}>
          {block.apartments.map(apartment => (
            <Link to={apartment.slug} key={apartment.name} className="apartment-overview-entry">
              <ScrollAnimation animateIn="fadeIn">
                <h2 className="apartment-overview-entry-name">{apartment.name}</h2>
                <div className="apartment-overview-entry-teaser">{apartment.teaser}</div>
                {(apartment.images && apartment.images.length > 0) &&
                  <img className="img-fluid" src={apartment.images[0].fixed.src} />
                }            </ScrollAnimation>

            </Link>
          ))}
        </Slider>
      </div>
    </div>
  )
}
