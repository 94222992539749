import React, { FC, useState } from "react"
import Slider from 'react-slick'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"


export const FaqWrapper: FC<{ block: any }> = ({ block }) => {
    const intl = useIntl();
    const faqs = block.content;

    return (
        <div className="faq-wrapper">

            <div className="row">
                <div className="col-md-4">
                    <div className="faq-bg">
                        <img alt={block.name} src={block.image.fixed.src} />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="container">
                        <h1 className=" text-white">{block.name}</h1>
                        <Accordion>
                            {faqs.map((faq: any, index: number) => (
                                <div className="faq-entry" key={faq.question + index}>
                                    <Accordion.Toggle className="faq-entry-question" eventKey={index.toString()}>
                                        <span>{faq.question}</span>
                                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse className="faq-entry-answer mt-3" eventKey={index.toString()}>
                                        <div className="text-white">
                                            <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(faq.answer.raw)).replace('<a', '<a target="_blank"') }}></div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ))}
                        </Accordion>
                    </div>

                    {intl.locale == 'en' &&
                    <div class="elfsight-app-74c00d23-0161-4db6-b17f-319e5dfb70d3"></div>
                    }
                    {intl.locale == 'de' &&
                       <div className="elfsight-app-317b144b-5ba4-499c-9e3f-4a71232a429a"></div>
                    }
                </div>
            </div>
        </div>
    )
}
