import React, { FC, useState } from "react"
import { Link } from "gatsby-plugin-intl";
import ScrollAnimation from 'react-animate-on-scroll';

export const ApartmentList: FC<{ block: any }> = ({ block }) => {
    return (
        <div className="apartment-list container">
            {block.items.map(apartment => (
                <ScrollAnimation animateIn="fadeIn">
                    <div className="row apartment-list-entry">
                        <div className="col-md-6 px-0">
                            <Link to={apartment.slug} key={apartment.name} className="apartment-list-entry">
                                {apartment.images.length > 0 &&
                                    <img className="img-fluid" src={apartment.images[0].fixed.src} />
                                }
                            </Link>
                        </div>
                        <div className="col-md-6 apartment-list-entry-content">
                            <Link to={apartment.slug} key={apartment.name} className="apartment-list-entry">
                                <h2 className="apartment-list-entry-name">{apartment.name}</h2>
                                <div className="apartment-list-entry-teaser">{apartment.teaser}</div>
                                <span className="apartment-list-entry-btn">read more</span>
                            </Link>
                        </div>
                    </div>
                </ScrollAnimation>

            ))}
        </div>
    )
}
