import React, { FC, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Parallax from 'react-rellax'
import ScrollAnimation from 'react-animate-on-scroll';

export const IntroText: FC<{ block: any }> = ({ block }) => {
    return (
        <div className="intro-text" style={{ backgroundImage: `url(${block.background.fixed.src})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md d-none d-lg-flex align-items-end">
                        <div className="intro-text-slogan-placeholder"></div>
                        <div className="intro-text-slogan">
                            <div><Parallax speed={-1} centered><span>Boutique</span></Parallax></div>
                            <div><Parallax speed={1} centered><span>Oldschool</span></Parallax></div>
                        </div>

                        <ScrollAnimation animateIn="fadeIn">
                            {block.imageFirst &&
                                <img className="img-fluid" src={block.imageFirst.fixed.src} />
                            }
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md">
                        <ScrollAnimation animateIn="fadeIn">
                            <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.textSecond.raw)) }}></div>
                        </ScrollAnimation>
                        <Parallax speed={-0.22} centered>
                            <StaticImage className="intro-text-signature" src="../../images/signature.png" />
                        </Parallax>
                        <ScrollAnimation animateIn="fadeIn">
                            {block.imageSeecond &&
                                <img className="img-fluid mt-5 mb-5" src={block.imageSeecond.fixed.src} />
                            }
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn">
                            <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(block.textFirst.raw)) }}></div>
                            {block.linkValue &&
                                <a
                                    href={block.linkValue}
                                    className="nav-cta mt-0"
                                    target="_blank"
                                >
                                    <p className="nav-cta-title">{block.linkText}</p>
                                    <StaticImage alt="book" src="../../images/orange-stroke.png" />
                                </a>
                            }
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    )
}
